import React, {ReactElement} from "react";

import {Form, Table, Alert, Button, DatePicker, message} from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";

import {useRouter, useQuery} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";
import {ICession} from "api/interfaces/Cession";
import {renderObservations, renderRecordActions, renderRecordDate} from "utils/helpers";
import { useStoreState } from "utils/store";
import api from "api";
import {dateFormats} from "../../utils/formats";


const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    from: undefined,
    to: undefined
  })
}

const renderParticipants = (text: string, record: ICession) : ReactElement => {
  const real = record.attributes.real_participants || "-";
  const predicted = record.attributes.predicted_participants || "-";

  return <span>{ real } / { predicted }</span>;
};

const CessionsList: React.FC = () => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const { tableProps, error, reload, search } = useTable<ICession>(api.cessions, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearch } = search!;

  const goToCession = (id: string) => router.push(`/cessions/${id}`);
  const goToCreateCession = () => router.push(`/cessions/new`);

  const handleDestroy = async (id: string) => {
    const response = await api.cessions.destroy(id);

    if (response.isSuccess())
      reload();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<ICession>[] = [
    {
      title: 'Inici',
      key: "from",
      render: renderRecordDate("from")
    },
    {
      title: 'Fi',
      key: "to",
      render: renderRecordDate("to")
    },
    { title: "Aforament real / previst ", key: "participants", render: renderParticipants },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToCession, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="from" label="Des de">
          <DatePicker className="w100" format={[dateFormats.display, "YYYY-MM-DD"]} placeholder="Sel·lecciona una data" />
        </Form.Item>
        <Form.Item name="to" label="Fins">
          <DatePicker className="w100" format={[dateFormats.display, "YYYY-MM-DD"]} placeholder="Sel·lecciona una data" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearch}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["cessions:create"]}>
          <Button icon={<PlusOutlined />} onClick={goToCreateCession}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  );
};

export default CessionsList;