import React, {ReactElement, useMemo} from "react";
import {Popconfirm, Alert, Button, message, Table, Tooltip} from "antd";
import {ColumnType} from "antd/lib/table/interface";
import {SyncOutlined, AuditOutlined, PlusOutlined, RetweetOutlined} from "@ant-design/icons";
import {PermissibleRender} from "@brainhubeu/react-permissible";

import api from "api";
import {IPayment} from "api/interfaces/Payment";
import {useInnerTable} from "hooks/useInnerTableApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions as renderDefaultRecordActions} from "utils/helpers";
import {useStoreState} from "utils/store";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {dateFormats} from "../../../utils/formats";
import RegistrationPaymentForm from "./payment_form";
import RegistrationRefundForm from "./refund_form";


interface PaymentsListProps {
  registrationId?: string,
  cessionId?: string,
}

const renderAmount = (text: string, record: IPayment) : ReactElement => {
  const amount = parseFloat(record.attributes.amount).toFixed(2);

  return record.attributes.payment_type === "charge"
    ? <>{amount} €</>
    : <>-{amount} €</>;
};

const renderFee = (text: string, record: IPayment) : ReactElement => {
  if (record.attributes.fee === null) {
    return <></>;
  }

  const amount = parseFloat(record.attributes.fee || '0').toFixed(2);

  return record.attributes.payment_type === "charge"
    ? <>{amount} €</>
    : <>-{amount} €</>;
};

const renderTax = (text: string, record: IPayment) : ReactElement => {
  const rate = (parseFloat(record.attributes.tax_rate) * 100).toFixed(2);
  const amount = parseFloat(record.attributes.included_tax_amount).toFixed(2);

  return record.attributes.payment_type === "charge"
    ? <>{amount} € ({rate}%)</>
    : <>-{amount} € ({rate}%)</>;
};

const renderDate = (text: string, record: IPayment) : ReactElement => {
  const date = record.attributes.date;

  return <span>{ date ? date.format(dateFormats.display) : null}</span>;
};

const rowClassName = (record: IPayment) => `payment-type-${record.attributes.payment_type}`

const PaymentsList: React.FC<PaymentsListProps> = ({ registrationId, cessionId }) => {
  const baseQuery = useMemo(() => ({ registration: registrationId, cession: cessionId }), [registrationId, cessionId]);
  const {tableProps, error, refresh, reload} = useInnerTable<IPayment>(api.payments, {baseQuery});

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const apiEndpoint = React.useMemo(() => api.activityRegistrations(registrationId), [registrationId]);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IPayment> => {
    return {
      title: "Nou pagament",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.payments.newInstance({
        activity_registration_id: registrationId,
        cession_id: cessionId,
        payment_type: "charge",
        status: "completed"
      })
    }
  }, [registrationId, cessionId, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IPayment>(api.payments, formOptions);

  const refundFormOptions = React.useMemo(() : useDrawerFormOptionsType<IPayment> => {
    return {
      title: "Nova devolució",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.payments.newInstance({})
    }
  }, [refresh]);

  const { create: createRefund, edit: editRefund, drawerProps: refundDrawerProps } = useDrawerForm<IPayment>(api.payments, refundFormOptions);

  const handleDestroy = async (id: string) => {
    const response = await api.payments.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const handleTaxesRecalculation = async () => {
    const response = await apiEndpoint.recalculateTaxes({ filters: { id: registrationId } });

    if (response.isSuccess()) {
      message.success('IVA recalculat correctament');
      refresh();
    } else {
      message.error("No s'ha pogut recalcular l'IVA de l'inscripció", 10);
    }
  }

  const renderRecordActions = (text: string, record: IPayment, index: number): ReactElement => {
    if (record.attributes.payment_type === "charge") {
      const defaultRecord = api.payments.newInstance({
        activity_registration_id: registrationId,
        cession_id: cessionId,
        refunded_payment_id: record.id,
        payment_type: "refund",
        status: "completed"
      });

      return (
        <>
          <Tooltip title="Cear devolució">
            <Button type="link" onClick={(ev) => createRefund(ev, defaultRecord)} icon={<RetweetOutlined />} />
          </Tooltip>
          <Tooltip title="Generar PDF">
            <Button type="link" icon={<AuditOutlined/>} href={`/api/payments/${record.id}.pdf`} target="_blank" />
          </Tooltip>
          {renderDefaultRecordActions(edit, handleDestroy)(text, record, index)}
        </>
      )
    } else {
      return (
        <>
          <Button type="link" icon={<AuditOutlined/>} href={`/api/payments/${record.id}.pdf`} target="_blank" />
          {renderDefaultRecordActions(editRefund, handleDestroy)(text, record, index)}
        </>
      )
    }
  }

  const columns: ColumnType<IPayment>[] = [
    {
      title: "Nº Registre",
      key: "code",
      dataIndex: ["attributes", "code"]
    },
    {
      title: 'Date',
      key: "date",
      align: "center",
      render: renderDate
    },
    {
      title: 'Mètode',
      key: "payment_method",
      align: "center",
      dataIndex: ["attributes", "payment_method_name"]
    },
    {
      title: 'Motiu devolució',
      key: "refund_reason",
      align: "center",
      dataIndex: ["attributes", "refund_reason_name"]
    },
    {
      title: 'Nº Devolució',
      key: "refunded_payment_code",
      align: "center",
      dataIndex: ["attributes", "refunded_payment_code"]
    },
    {
      title: 'Tipus de Servei',
      key: "payment_service",
      align: "center",
      dataIndex: ["attributes", "payment_service_name"]
    },
    {
      title: 'IVA',
      key: "included_tax_amount",
      align: "center",
      render: renderTax
    },
    {
      title: 'Import',
      key: "amount",
      align: "center",
      render: renderAmount
    },
    {
      title: 'Comissió',
      key: "fee",
      align: "center",
      render: renderFee
    },
    // {
    //   title: 'Observacions',
    //   key: "observations",
    //   ellipsis: { showTitle: false },
    //   render: renderObservations
    // },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions
    }
  ];

  const toolbar = (
    <div style={{ marginTop: "5px" }}>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["payments:create"]}>
        <Button icon={<PlusOutlined />} style={{ marginRight: "5px", marginBottom: "5px" }}  onClick={create} size="small">Afegir</Button>
      </PermissibleRender>
      { registrationId &&
        <Popconfirm placement="leftBottom" title="Esteu segurs de recalcular l'IVA?" onConfirm={() => handleTaxesRecalculation()} okText="Sí" cancelText="No">
          <Button icon={<SyncOutlined />} size="small">Recalcular IVA</Button>
        </Popconfirm>
      }

    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} rowClassName={rowClassName} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <RegistrationPaymentForm form={form} readOnly={readOnly} registrationId={registrationId} />
        )}
      </DrawerFormWithForwardRef>
      <DrawerFormWithForwardRef {...refundDrawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <RegistrationRefundForm form={form} readOnly={readOnly} registrationId={registrationId} />
        )}
      </DrawerFormWithForwardRef>
    </>
  )
}

export default PaymentsList;
