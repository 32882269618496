import React from "react";
import { Form, Row, Col, Input, Button, Select, Collapse } from "antd";
import {rowConfig, fourColumns, twoColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {ICession, INewCession} from "api/interfaces/Cession";
import {IQueryEditable} from "../../api/interfaces/Query";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";

interface CessionFormProps {
  api: IQueryEditable,
  record: ICession|INewCession,
  handleCreated?: (item: ICession | ICession[]) => void,
  handleUpdated?: (item: ICession) => void,
}

class InnerForm extends PageForm<ICession|INewCession> {}


const targetsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("cession_targets"), params: { items: "all" } };
const agesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("ages"), params: { items: "all" } };
const materialsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("materials"), params: { items: "all" } };

const CessionForm: React.FC<CessionFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  const { data: agesData, isFetching: isFetchingAges } = useAxiosRequest<{ data: IDictionary[] }>(agesRequest);
  const { data: ages = [] } = agesData || {};

  const { data: targetsData, isFetching: isFetchingTargets } = useAxiosRequest<{ data: IDictionary[] }>(targetsRequest);
  const { data: targets = [] } = targetsData || {};

  const { data: materialsData, isFetching: isFetchingMaterials } = useAxiosRequest<{ data: IDictionary[] }>(materialsRequest);
  const { data: materials = [] } = materialsData || {};

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Collapse defaultActiveKey={null} bordered={false} className="mb-20">
            <Collapse.Panel header="General" key="1">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "predicted_participants"]} label="Aforament previst">
                    <Input type="text" disabled={readOnly} placeholder="Número de persones" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "real_participants"]} label="Aforament real">
                    <Input type="text" disabled={readOnly} placeholder="Número de persones" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "target_ids"]} label="A qui s'adreça">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els elements" loading={isFetchingTargets} filterOption={filterOptionByLabel} showSearch>
                      {targets.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "age_ids"]} label="Edats">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els elements" loading={isFetchingAges} filterOption={filterOptionByLabel} showSearch>
                      {ages.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "material_ids"]} label="Materials">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els elements" loading={isFetchingMaterials} filterOption={filterOptionByLabel} showSearch>
                      {materials.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Observacions" key="3">
              <Form.Item name={["attributes", "observations"]}>
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default CessionForm;