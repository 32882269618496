import React from "react"
import {DatePicker, Form, Input, InputNumber, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import {IDictionary} from "../../../api/interfaces/Dictionary";
import {Routes} from "../../../api/routes";
import {dateFormats} from "../../../utils/formats";
import { IPaymentService } from "api/interfaces/PaymentService";

export interface IncidenciaLocalFormProps {
  registrationId: string;
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  payment_method_id: [{ required: true, message: "Aquest camp és requerit" }],
  payment_service_id: [ { required: true, message: "Aquest camp és requerit" } ]
};

const paymentsMethodsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("payment_methods"), params: { items: "all" } };
const paymentsServicesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("payment_services"), params: { items: "all", filters: { type: "charge"} } };


const RegistrationPaymentForm: React.FC<IncidenciaLocalFormProps> = ({ registrationId, readOnly = false }) => {
  const { data: methodsData, isFetching: isFetchingMethods } = useAxiosRequest<{ data: IDictionary[] }>(paymentsMethodsRequest);
  const { data: methods = [] } = methodsData || {};

  const { data: servicesData, isFetching: isFetchingServices } = useAxiosRequest<{ data: IPaymentService[] }>(paymentsServicesRequest);
  const { data: services = [] } = servicesData || {};

  return (
    <>
      <Form.Item name={["attributes", "activity_registration_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "cession_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "payment_type"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "status"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "code"]} label="Codi">
        <Input type="text" disabled placeholder="Auto generat al crear el registre"/>
      </Form.Item>
      <Form.Item name={["attributes", "date"]} label="Data d'inici">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "payment_method_id"]} label="Mètode de pagament" rules={rules.payment_method_id}>
        <Select disabled={readOnly} placeholder="Sel·lecciona un element" loading={isFetchingMethods} filterOption={filterOptionByLabel} showSearch>
          {methods.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "payment_service_id"]} label="Tipus de Servei" rules={rules.payment_service_id}>
        <Select disabled={readOnly} placeholder="Sel·lecciona un element" loading={isFetchingServices} filterOption={filterOptionByLabel} showSearch>
          {services.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "amount"]} label="Quantitat">
        <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
      </Form.Item>
      {/*<Form.Item name={["attributes", "observacions"]} label="Observacions">*/}
      {/*  <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />*/}
      {/*</Form.Item>*/}
    </>
  )
};

export default RegistrationPaymentForm;
