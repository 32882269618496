import React, {useState, ReactNode} from "react";
import { Link, useLocation, RouteProps } from "react-router-dom";
import useMediaQuery from 'use-media-antd-query';

import ProLayout, {
  MenuDataItem,
} from "@ant-design/pro-layout";

import logo from "assets/logo_escut.svg";
import settings from "utils/settings";
import GlobalHeaderRight from "components/GlobalHeader/RightContent";
import { useStoreState } from "utils/store";
import { hasPermission } from "utils/helpers";


const ApplicationLayout: React.FC<RouteProps> = (props) => {
  const { children } = props;

  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  const colSize = useMediaQuery();
  const isMobile = (colSize === 'sm' || colSize === 'xs');

  const location = useLocation();
  const routeList = useStoreState(state => state.app.routeList);
  const currentPermissions = useStoreState(state => state.app.currentPermissions);

  const handleMenuCollapse = (payload: boolean): void => {
    setMenuCollapsed(payload);
  };

  const route = { routes: routeList };
  /**
   * use Authorized check all menu item
   */
  const menuDataRender = (menuList: MenuDataItem[]): MenuDataItem[] =>
    menuList.map(item => {
      if (item.hideOnMobile && isMobile) {
        return null;
      }

      const localItem = {
        ...item,
        children: item.children ? menuDataRender(item.children) : [],
      };
      return (hasPermission(currentPermissions, item.authority) ? localItem : null) as MenuDataItem;
    })
    .filter(item => item) as MenuDataItem[];

  return (
    <ProLayout
      contentStyle={{minHeight:"100%"}}

      logo={logo}
      menuHeaderRender={(logoDom, titleDom) => (
        <Link to="/">
          {logoDom}
          {titleDom}
        </Link>
      )}
      collapsed={isMenuCollapsed}
      onCollapse={handleMenuCollapse}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || menuItemProps.children || !menuItemProps.path) {
          return defaultDom;
        }
        return <Link to={menuItemProps.path}>{defaultDom}</Link>;
      }}
      breadcrumbRender={(routers = []) => [
        {
          path: '/',
          breadcrumbName: "Inici", /*formatMessage({
            id: 'menu.home',
            defaultMessage: 'Home',
          }),*/
        },
        ...routers,
      ]}
      itemRender={(route, params, routes, paths) => {
        const first = routes.indexOf(route) === 0;
        return first ? (
          <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
        ) : (
          <span>{route.breadcrumbName}</span>
        );
      }}
      footerRender={false}
      menuDataRender={menuDataRender}
      // formatMessage={formatMessage}
      rightContentRender={(rightProps): ReactNode => <GlobalHeaderRight {...rightProps}/>}
      // locale="it-IT"
      location={location}
      route={route}

      {...props}
      {...settings}
    >
      {children}
      {/* {routeList.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            hasPermission(currentPermissions, route.authority) ? <route.component /> : <Unauthorized />
          )}
        />
      ))} */}
    </ProLayout>
  );
};

export default ApplicationLayout;
