import React from "react";
import { Form, Row, Col, Input, Button } from "antd";
import {rowConfig, twoColumns, oneColumn} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IIndividual} from "api/interfaces/Individual";
import {IQueryEditable} from "api/interfaces/Query";

interface IndividualFormProps {
  api: IQueryEditable,
  record: IIndividual,
  handleCreated?: (item: IIndividual | IIndividual[]) => void,
  handleUpdated?: (item: IIndividual) => void,
}

class InnerForm extends PageForm<IIndividual> {}

const SalutForm: React.FC<IndividualFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "allergies"]} label="Al·lèrgies">
                <Input.TextArea disabled={readOnly} placeholder="Al·lèrgies" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "mediacions"]} label="Mediacions">
                <Input.TextArea disabled={readOnly} placeholder="Mediacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "malalties_croniques"]} label="Malalties cròniques">
                <Input.TextArea disabled={readOnly} placeholder="Malalties cròniques" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "vacunes"]} label="Vacunes">
                <Input.TextArea disabled={readOnly} placeholder="Vacunes" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "altres_salut"]} label="Altres salut">
                <Input.TextArea disabled={readOnly} placeholder="Altres salut" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default SalutForm;
