import React from "react";
import { Form, Row, Col, Input, Button, DatePicker, Select, Collapse, InputNumber } from "antd";
import {rowConfig, fourColumns, twoColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IIndividual} from "api/interfaces/Individual";
import {IQueryEditable} from "../../api/interfaces/Query";
import { dateFormats } from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import { useStoreState } from "utils/store";

interface IndividualFormProps {
  api: IQueryEditable,
  record: IIndividual,
  handleCreated?: (item: IIndividual | IIndividual[]) => void,
  handleUpdated?: (item: IIndividual) => void,
}

class InnerForm extends PageForm<IIndividual> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const countriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("countries"), params: { items: "all" } };
const residenceTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("residence_place_types"), params: { items: "all" } };
const gendersRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("genders"), params: { items: "all" } };

const IndividualForm: React.FC<IndividualFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  const { data: countriesData, isFetching: isFetchingCountries } = useAxiosRequest<{ data: IDictionary[] }>(countriesRequest);
  const { data: countries = [] } = countriesData || {};

  const { data: residenceTypesData, isFetching: isFetchingResidenceTypes } = useAxiosRequest<{ data: IDictionary[] }>(residenceTypesRequest);
  const { data: residenceTypes = [] } = residenceTypesData || {};

  const { data: gendersData, isFetching: isFetchingGenders } = useAxiosRequest<{ data: IDictionary[] }>(gendersRequest);
  const { data: genders = [] } = gendersData || {};

  const { attributes: { config: { kids = false } } } = useStoreState(state => state.app.currentApplication);

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Collapse defaultActiveKey={['1']} bordered={false} className="mb-20">
            <Collapse.Panel header="General" key="1">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "dni"]} label="DNI" rules={rules.required} style={{marginBottom: 0}}>
                    <Input type="text" disabled={readOnly} placeholder="DNI" />
                  </Form.Item>
                  { record.attributes.other_individuals_with_same_dni.length > 0 &&
                    <div className="ant-form-item">
                      Altres individus amb el mateix dni: <br />{record.attributes.other_individuals_with_same_dni.join(", ")}
                    </div>
                  }
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "first_name"]} label="Nom" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Nom" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "last_name"]} label="Cognoms" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Cognoms" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "birthdate"]} label="Data de naixement">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "phones"]} label="Telèfons de contacte">
                    <Input type="text" disabled={readOnly} placeholder="Telèfons de contacte" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "email"]} label="Correu electrònic" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Correu electrònic" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "gender_id"]} label="Sexe" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingGenders} filterOption={filterOptionByLabel} showSearch>
                      {genders.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              { kids &&
                <Row {...rowConfig}>
                  <Col {...fourColumns}>
                    <Form.Item name={["attributes", "nom_complert_tutor_legal"]} label="Nom i cognoms tutor/a legal">
                      <Input type="text" disabled={readOnly} placeholder="Nom i cognoms" />
                    </Form.Item>
                  </Col>
                  <Col {...twoColumns}>
                   <Form.Item name={["attributes", "parents_country_ids"]} label="País d'origen progenitors">
                     <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingCountries} filterOption={filterOptionByLabel} showSearch>
                       {countries.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
                </Row>
              }
            </Collapse.Panel>
            <Collapse.Panel header="Informació d'adreça" key="2">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "residence_place_type_id"]} label="Lloc de residència" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Al barri, al districte, ..." loading={isFetchingResidenceTypes} filterOption={filterOptionByLabel} showSearch>
                      {residenceTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "calle"]} label="Calle">
                    <Input type="text" disabled={readOnly} placeholder="Calle" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "numero"]} label="Numero">
                    <InputNumber type="text" disabled={readOnly} placeholder="Numero" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "bloc"]} label="Bloc">
                    <Input type="text" disabled={readOnly} placeholder="Bloc" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "escala"]} label="Escala">
                    <Input type="text" disabled={readOnly} placeholder="Escala" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "pis"]} label="Pis">
                    <Input type="text" disabled={readOnly} placeholder="Pis" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "porta"]} label="Porta">
                    <Input type="text" disabled={readOnly} placeholder="Porta" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "lloc_empadronament_id"]} label="Lloc d'empardonament">
                    <Select disabled={readOnly} placeholder="Al barri, al districte, ..." loading={isFetchingResidenceTypes} filterOption={filterOptionByLabel} showSearch>
                      {residenceTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Observacions" key="3">
              <Form.Item name={["attributes", "observations"]}>
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default IndividualForm;
