import React, { useMemo } from "react";

import { Form, Table, Alert, Button, Input, DatePicker, Select } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined, DownloadOutlined, PrinterOutlined } from "@ant-design/icons";

import {useRouter, useQuery} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";
import {IActivity} from "api/interfaces/Activity";
import {filterOptionByLabel, renderRecordActions, renderRecordDate} from "utils/helpers";
import { useStoreState } from "utils/store";
import api from "api";
import qs from "qs";
import { compact } from "lodash";
import { dateFormats, dateRangeFromServer } from "utils/formats";
import { useAxiosRequest } from "use-axios-request";
import { Routes } from "api/routes";
import { axiosConfig } from "utils/request";
import { IDictionary } from "api/interfaces/Dictionary";

const { RangePicker } = DatePicker;

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined,
    category: undefined,
    start_date: undefined,
    end_date: undefined
  })
}

const activityCategoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_categories"), params: { items: "all" } };

const ActivitiesList: React.FC = () => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const searchQuery = useMemo(() => {
    if (!query) {
      return undefined;
    }

    const { date_between: dateRange } = query;

    const date_between = dateRange ? dateRangeFromServer(dateRange) : undefined;

    return { ...query, date_between };
  }, [query]);

  const { tableProps, error, reload, search } = useTable<IActivity>(api.activities, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const { data: activityCategoriesData, isFetching: isFetchingActivityCategories } = useAxiosRequest<{ data: IDictionary[] }>(activityCategoriesRequest);
  const { data: activityCategories = [] } = activityCategoriesData || {};

  const goToActivity = (id: string) => router.push(`/activities/${id}`);
  const goToAssistence = (id: string) => router.push(`/activities/${id}?tab=3`);
  const goToCreateActivity = () => router.push(`/activities/new`);
  const exportURL = `/api/activities?${qs.stringify({filters:query, export: "xlsx"}, { encodeValuesOnly: true, arrayFormat: "brackets" })}`
  const printURL = `/api/activities/print.pdf?${qs.stringify({filters:query}, { encodeValuesOnly: true, arrayFormat: "brackets" })}`

  const columns: ColumnType<IActivity>[] = [
    {
      title: "Codi",
      key: "id",
      dataIndex: ["id"]
    },
    {
      title: "Nom",
      key: "name",
      dataIndex: ["attributes", "name"]
    },
    { title: "Inici", key: "start", render: renderRecordDate("start_date") },
    { title: "Fi", key: "end", render: renderRecordDate("end_date") },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <Button type="link" onClick={() : void => goToAssistence(record.id)} style={{fontWeight: 'bold'}}>A</Button>
            { renderRecordActions(goToActivity)(text, record, index) }
          </>
        );
      }
    }
  ];

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const dateRange = compact<moment.Moment>(formValues["date_between"]);

    const date_between = dateRange.length > 0
      ? [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')].join("...")
      : undefined;

    searchSubmit({ ...formValues, date_between});
  }

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={searchQuery} layout="inline" onFinish={onFinish}>
        <Form.Item name="category">
          <Select placeholder="Categoria" loading={isFetchingActivityCategories} filterOption={filterOptionByLabel} dropdownMatchSelectWidth={false} allowClear showSearch>
          {activityCategories.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item name="date_between">
          <RangePicker className="w100" format={dateFormats.display} placeholder={["Des de", "Fins"]} separator="-" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={goToCreateActivity} style={{marginRight: "5px"}}>Afegir</Button>
        </PermissibleRender>
        <Button icon={<PrinterOutlined />} href={printURL} target="_blank" style={{marginRight: "5px"}}>Imprimir</Button>
        <Button icon={<DownloadOutlined />} href={exportURL} target="_blank">Descarregar</Button>
      </div>
    </div>
  );


  const errorMessage = (
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  );
};

export default ActivitiesList;
