import React, {ReactElement, useMemo} from "react";
import {Button, Table, Alert} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations, handleDestroy} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IActivitySession} from "api/interfaces/activity/Session";
import CreateMultipleSessionsForm from "../forms/create_multiple_sessions";
import {useRouter} from "../../../hooks/useRouter";
import {timeFormats} from "../../../utils/formats";

interface SessionsListProps {
  activityId: string
}

const renderSessionTime = (text: string, record: IActivitySession) : ReactElement => {
  const starts_at = record.attributes.starts_at;
  const ends_at = record.attributes.ends_at;

  return <span>{ starts_at.format(timeFormats.display) } - { ends_at.format(timeFormats.display) }</span>;
};

const SessionsList: React.FC<SessionsListProps> = ({ activityId }) => {
  const apiEndpoint = React.useMemo(() => api.activitySessions(activityId), [activityId]);

  const router = useRouter();
  const goToSession = (id: string) => router.push(`/activities/${activityId}/sessions/${id}`);
  const goToCreateSession = () => router.push(`/activities/${activityId}/sessions/new`);

  const { tableProps, error, refresh, reload } = useInnerTable<IActivitySession>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IActivitySession> => {
    return {
      title: "Crear múltiple sessions",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps } = useDrawerForm<IActivitySession>(apiEndpoint, formOptions);

  const destroyAction = useMemo(() => {
    return handleDestroy(apiEndpoint, refresh)
  }, [apiEndpoint, refresh]);

  const columns: ColumnType<IActivitySession>[] = [
    { title: "Espai", key: "space", dataIndex: ["attributes", "space_name"] },
    { title: "Tallerista", key: "tallerista", dataIndex: ["attributes", "tallerista_name"] },
    { title: "Dia", key: "day", render: renderRecordDate("session_date") },
    { title: "Hora", key: "hour", render: renderSessionTime },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToSession, destroyAction)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={goToCreateSession} style={{marginRight: "5px"}}>Afegir</Button>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir múltiple</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <CreateMultipleSessionsForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default SessionsList;
