import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import SpaceStatForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import {useQuery, useRouter} from "hooks/useRouter";
import api from "../../api";
import {ISpaceStat} from "api/interfaces/SpaceStat";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";

export interface IState {
  item?: ISpaceStat
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const SpaceStatEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { spaceStatId } = useQuery();
  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);
  const goToIndex = (item: ISpaceStat) => router.push(`/space-stats`);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.spaceStats.show(spaceStatId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (spaceStatId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [spaceStatId, dispatch]);

  if (!spaceStatId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const title = spaceStatId === "new" ? "Crear" : "Editar"

  return (
    <PageHeaderWrapper title={`${title} registre d'utilització`}>
      <SpaceStatForm api={api.spaceStats} record={state.item} handleCreated={goToIndex} handleUpdated={goToIndex} />
    </PageHeaderWrapper>
  );
};

export default SpaceStatEditPage;