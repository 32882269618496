import React from "react";
import {Form, Row, Col, Input, Button, DatePicker, InputNumber, TimePicker, Card} from "antd";
import {fourColumns, rowConfig, sixColumns, threeColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {ISpaceStat} from "api/interfaces/SpaceStat";
import {IQueryEditable} from "../../api/interfaces/Query";
import { dateFormats, timeFormats } from "utils/formats";

interface ActivityFormProps {
  api: IQueryEditable,
  record: ISpaceStat,
  handleCreated?: (item: ISpaceStat | ISpaceStat[]) => void,
  handleUpdated?: (item: ISpaceStat) => void,
}

class InnerForm extends PageForm<ISpaceStat> {}


const UtilizationForm: React.FC<ActivityFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
    >
      {({ form, loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "date"]} label="Data">
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "starts_at"]} label="Des de">
                <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "ends_at"]} label="Fins">
                <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Form.List name={["attributes", "stats_attributes"]}>
              {(fields /*, { add, remove }*/) => (fields.map(field => (
                <Col key={field.key} {...fourColumns} className="mb-15">
                  <Card title={form.getFieldValue(["attributes", "stats_attributes", field.name, 'space_name'])} headStyle={{textAlign: "center"}}>
                    <Form.Item name={[field.name, 'id']} style={{display: "none"}}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name={[field.name, 'space_id']} style={{display: "none"}}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Row {...rowConfig}>
                      <Col {...threeColumns}>
                        <Form.Item name={[field.name, 'dones']} label="Dones">
                          <InputNumber disabled={readOnly} min={0} step={1} />
                        </Form.Item>
                      </Col>
                      <Col {...threeColumns}>
                        <Form.Item name={[field.name, 'homes']} label="Homes">
                          <InputNumber disabled={readOnly} min={0} step={1} />
                        </Form.Item>
                      </Col>
                      <Col {...threeColumns}>
                        <Form.Item name={[field.name, 'no_binaris']} label="No binaris">
                          <InputNumber disabled={readOnly} min={0} step={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )))}
            </Form.List>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default UtilizationForm;
