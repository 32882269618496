import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { ICessionIndividualResult, ICessionIndividualsResult, ICessionIndividual } from '../../interfaces/cession/Individual'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";

export default class CessionIndividuals extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly cession_id: string;

  constructor(cession_id: string, host?: string) {
    super(host);
    this.cession_id = cession_id;
  }

  public async list(params: IQuery = {}): Promise<ICessionIndividualsResult> {
    return await this.request(GET, Routes.cessionIndividualsPath(this.cession_id), {}, params) as ICessionIndividualsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICessionIndividualResult> {
    return await this.request(GET, Routes.cessionIndividualPath(this.cession_id, id), {}, params) as ICessionIndividualResult
  }

  public async create(params: JsonApiDocument): Promise<ICessionIndividualResult> {
    return await this.request(POST, Routes.cessionIndividualsPath(this.cession_id), {}, params) as ICessionIndividualResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICessionIndividualResult> {
    return await this.request(PATCH, Routes.cessionIndividualPath(this.cession_id, id), {}, params) as ICessionIndividualResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.cessionIndividualPath(this.cession_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICessionIndividual {
    return {
      id: null,
      type: "cessions_individuals",
      attributes: {
        individual_id: null,
        individual_name: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}