
import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import CessionForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import {useQuery} from "hooks/useRouter";
import {Tabs} from "antd";
import api from "../../api";
import {ICession} from "api/interfaces/Cession";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import DatesList from "./sections/dates";
import PaymentsList from "pages/shared/payments/list";
import EntitatsList from "./sections/entities";
import IndividusList from "./sections/individuals";

export interface IState {
  item?: ICession
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const CessionEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { cessionId } = useQuery();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.cessions.show(cessionId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (cessionId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [cessionId, dispatch]);

  if (!cessionId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  return (
    <PageHeaderWrapper title="Editar cessió">
      <CessionForm api={api.cessions} record={state.item} />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" animated={false} className="mb-20">
        <Tabs.TabPane tab="Dates" key="1">
          <DatesList cessionId={cessionId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pagaments" key="2">
          <PaymentsList cessionId={cessionId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Entitats" key="3">
          <EntitatsList cessionId={cessionId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Individus" key="4">
          <IndividusList cessionId={cessionId} />
        </Tabs.TabPane>
      </Tabs>
    </PageHeaderWrapper>
  );
};

export default CessionEditPage;
