import React, { useState, useEffect } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { stringify } from 'querystring';
import { useStoreActions, useStoreState } from 'utils/store';
import { Redirect, RouteProps } from 'react-router-dom';
import Unauthorized from 'components/Unauthorized';

const SecurityLayout: React.FC<RouteProps> = (props) : React.ReactElement => {
  const { children } = props;

  const [isReady, setReady] = useState<Boolean>(false);

  const loading = useStoreState(state => state.app.loading);
  const isLoggedIn = useStoreState(state => state.app.isLoggedIn);
  const hasPermissions = useStoreState(state => state.app.hasPermissions);

  const fetchCurrentUser = useStoreActions(actions => actions.app.fetchCurrent);

  useEffect(() => {
    fetchCurrentUser();
    setReady(true);
  }, [fetchCurrentUser]);


  const queryString = stringify({
    redirect: window.location.href,
  });

  if ((!isLoggedIn && loading) || (!hasPermissions && loading) || !isReady) {
    return <PageLoading />;
  }

  if (!isLoggedIn) {
    return <Redirect to={`/auth/login?${queryString}`}></Redirect>;
  }

  if (!hasPermissions) {
    return <Unauthorized />
  }

  return <>{children}</>;
};

export default SecurityLayout;
