import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IPaymentResult, IPaymentsResult, IPayment } from '../interfaces/Payment'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import moment from 'moment'

export default class Payments extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["date"];

  public async list(params: IQuery = {}): Promise<IPaymentsResult> {
    return await this.request(GET, Routes.paymentsPath(), {}, params) as IPaymentsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IPaymentResult> {
    return await this.request(GET, Routes.paymentPath(id), {}, params) as IPaymentResult
  }

  public async create(params: JsonApiDocument): Promise<IPaymentResult> {
    return await this.request(POST, Routes.paymentsPath(), {}, params) as IPaymentResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IPaymentResult> {
    return await this.request(PATCH, Routes.paymentPath(id), {}, params) as IPaymentResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.paymentPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attributes: Partial<IPayment["attributes"]>) : IPayment {
    return {
      id: null,
      type: "payments",
      attributes: {
        code: "",
        date: moment.utc(),
        amount: "",
        fee: "",
        status: "",
        payment_type: "",
        activity_registration_id: "",
        cession_id: "",
        payment_method_id: "",
        payment_method_name: "",
        refunded_payment_id: "",
        refund_reason_id: "",
        refund_reason_name: "",
        payment_service_id: "",
        payment_service_name: "",
        included_tax_amount: "",
        tax_rate: "",
        ...attributes
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
