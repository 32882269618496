import Axios, { AxiosError, AxiosInstance } from 'axios'
import * as qs from 'qs'
import { DELETE, GET } from './constants'
import {
  BasicApiError, ExpandedApiError, MisconfigurationError, NoResponseError, ApiError, ApiSDKError
} from './errors'
import Result from './helpers/Result'
import { ErrorClass } from './interfaces/errors/ErrorClass'
import {JsonApiResponse, JsonApiDocument, EmptyResponse} from './interfaces/JsonApi'
import { ResultResponse } from './interfaces/ResultResponse'
import { IToken } from './interfaces/Token'
import {dateFormats, dateFromServer, timeFromServer} from 'utils/formats'
import { forEach, isArray } from 'lodash'
import moment from "moment";
import store from 'utils/store'
import history from 'utils/history'

export default class Http {
  public host: string
  public axios: AxiosInstance

  protected dateFields: string[] = [];
  protected dateTimeFields: string[] = [];

  constructor(host?: string) {
    this.host = host || process.env.BACKEND_HOST || 'http://localhost:3000/'

    this.axios = Axios.create({
      baseURL: this.host,
      withCredentials: true,
      xsrfCookieName: "CSRF-TOKEN",
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params) => {
        if (params.filters) {
          forEach(params.filters, (value, key) => {
            if (moment.isMoment(value)) {
              params.filters[key] = value.format(dateFormats.server)
            }
          })
        }

        return qs.stringify(params, { encodeValuesOnly: true, arrayFormat: 'brackets' })
      }
    })
  }

  protected async request(
    method: string, route: string, tokens: IToken = {}, params: any = {}
  ): Promise<ResultResponse<JsonApiResponse>> {
    try {
      let res
      const reqFunc = this.axios[method]
      const headers = this.requestHeaders(tokens)

      if (method === GET || method === DELETE) {
        res = await reqFunc(route, { params, headers })
      } else {
        res = await reqFunc(route, { data: params }, { headers })
      }

      return Result.success(this.parseResponse(res.data))
    } catch (error) {
      console.log(error);
      return Result.fail(this.processError(error))
    }
  }

  private parseResponse(body: JsonApiResponse | EmptyResponse) {
    if (body) {
      const {data} = body;

      if (isArray(data)) {
        data.forEach(item => {
          this.parseItem(item)
        })
      } else {
        this.parseItem(data)
      }
    }

    return body;
  }

  private parseItem(item: JsonApiDocument) {
    this.dateFields.forEach(field => {
      item.attributes[field] = dateFromServer(item.attributes[field]);
    });

    this.dateTimeFields.forEach(field => {
      item.attributes[field] = timeFromServer(item.attributes[field]);
    });
  }

  /**
   * HTTP error code returned by Api is not indicative of its response shape. This function attempts to figure out the
   * information provided from Api and use whatever is available.
   */
  private classifyError(error: AxiosError): ErrorClass {
    const { error: errorSummary, errors } = error.response.data

    if (typeof errorSummary === 'string') {
      if (typeof errors === 'object') {
        return ErrorClass.FULL
      }
      return ErrorClass.BASIC
    }
    return ErrorClass.LIMITED
  }

  private processError(error: AxiosError): ApiSDKError {
    if (error.response) {
      if (error.response.status === 401) {
        store.getActions().app.signOut({ history });
        return new NoResponseError();
      }
      // Error from Api outside HTTP 2xx codes
      return this.processApiError(error)
    } else if (error.request) {
      // No response received from Api
      return new NoResponseError()
    } else {
      // Incorrect request setup
      return new MisconfigurationError(error.message)
    }
  }

  private processApiError(error: AxiosError): ApiError {
    const { error: errorSummary, errors } = error.response.data
    const errorClass = this.classifyError(error)

    if (errorClass === ErrorClass.FULL) {
      return new ExpandedApiError(error.response, errorSummary, errors)
    } else if (errorClass === ErrorClass.BASIC) {
      return new BasicApiError(error.response, errorSummary)
    } else {
      return new ApiError(error.response)
    }
  }

  private requestHeaders(tokens) {
    const header = {}

    if (tokens.bearerToken) {
      header['Authorization'] = `Bearer ${tokens.bearerToken}`
    }

    return header
  }
}
