import React, { useMemo } from "react";
import { Table, Input, Form, Button, Typography, Alert } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnType } from "antd/lib/table/interface";
import { useTable } from "hooks/useTableApi";
import { useQuery } from "hooks/useRouter";
import { renderRecordActions, renderBooleanColumn } from "utils/helpers";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { ModalFormWithForwardRef, ModalFormChildProps } from "components/ModalFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import DictionaryForm from "./form";
import { startsWith } from "lodash";

import api from "api";
import {IDictionary} from "api/interfaces/Dictionary";
import ActivityTypeForm from "./activity_type_form";
import TaxCategoryForm from "./tax_category_form";

const { Title } = Typography;

export interface DictionaryProps {
  path: string,
  title: string
}

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const Dictionary: React.FC<DictionaryProps> = ({ path, title }) => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.dictionaries(path), [path]);

  const { filters: query } = useQuery();

  const { tableProps, refresh, search } = useTable<IDictionary>(apiEndpoint, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IDictionary> => {
    return {
      title,
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh, title])

  const { create, edit, drawerProps } = useDrawerForm<IDictionary>(apiEndpoint, formOptions);

  const columns: ColumnType<IDictionary>[] = useMemo(() => {
    const tableColumns: ColumnType<IDictionary>[] = [
      {
        title: 'Nom',
        dataIndex: ["attributes", "name"],
        key: "attribues.name"
      },
      {
        title: " ",
        key: "actions",
        align: "right",
        render: renderRecordActions(edit)
      }
    ];

    switch(path) {
      case "activity_types":
        tableColumns.splice(1, 0, { title: "Esportiva", key: "is_sport", align: "center", render: renderBooleanColumn("is_sport") });
        break;
      case "tax_categories":
        tableColumns.splice(1, 0, { title: "Taxa", key: "rate", align: "center", dataIndex: ["attributes", "rate"] });
        break;
      default:
        break;
    }

    return tableColumns;
  }, [edit, path]);

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name" style={{marginRight: 0}}>
          <Input.Search placeholder="Nom" loading={tableProps.loading as boolean} onSearch={form.submit} />
        </Form.Item>
        <Form.Item>
         <Button type="link" onClick={resetSearh}>Reiniciar</Button>
        </Form.Item>
      </Form>
      <div>
        { !startsWith(path, "av_") &&
          <PermissibleRender userPermissions={userPermissions} requiredPermissions={["dictionaries:create"]}>
            <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
          </PermissibleRender>
        }
        { startsWith(path, "av_") &&
          <Alert
            showIcon
            type="warning"
            message="Si us plau, contacta amb l'administrador per modificar aquest diccionari"
          /> }
      </div>
    </div>
  );

  return (
    <>
      <Title level={3}>{title}</Title>
      { toolbar }
      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => {
          switch (path) {
            case "activity_types":
              return <ActivityTypeForm form={form} readOnly={readOnly} />;
            case "tax_categories":
              return <TaxCategoryForm form={form} readOnly={readOnly} />;
            default:
              return <DictionaryForm form={form} readOnly={readOnly} />;
          }
        }}
      </ModalFormWithForwardRef>
    </>
  );
};

export default Dictionary;
