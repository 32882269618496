import React, {useReducer} from "react";
import CessionForm from "./form";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { useRouter } from "hooks/useRouter";
import api from "api";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import {ICession, INewCession} from "api/interfaces/Cession";

export interface IState {
  item?: INewCession
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: api.cessions.newInstance(),
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const CessionEditPage: React.FC = () => {
  const router = useRouter();
  const goToCession = (item: ICession) => router.push(`/cessions/${item.id}`);

  const [state] = useReducer(reducer, initialState);

  if (!state.item) { return <LoadingError /> }

  return (
    <PageHeaderWrapper title="Crear cessió">
      <CessionForm api={api.cessions} record={state.item} handleCreated={goToCession} />
    </PageHeaderWrapper>
  );
};

export default CessionEditPage;