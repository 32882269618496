import RRule from 'rrule';
// import { values } from 'lodash';

const computeWeekly = ({ interval, days }) => ({
  freq: RRule.WEEKLY,
  interval,
  byweekday: days,
});

export default computeWeekly;
