import React from "react"
import { Form, Input } from "antd"
import { FormInstance } from "antd/lib/form";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  name: [ { required: true, message: "Aquest camp és requerit" } ],
  rate: [ { required: true, message: "Aquest camp és requerit" } ],
}

const TaxCategoryForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.name}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "rate"]} label="Tax" rules={rules.rate}>
        <Input disabled={readOnly} placeholder="Tax" />
      </Form.Item>
    </>
  )
}

export default TaxCategoryForm;
