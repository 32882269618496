import React, {ReactElement} from "react";
import {Button, Table, Alert, message} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDate, renderObservations} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {ICessionDate} from "api/interfaces/cession/Date";
import DatesForm from "../forms/date";
import CreateMultipleDatesForm from "../forms/create_multiple_dates";
import {timeFormats} from "../../../utils/formats";

interface DatesListProps {
  cessionId: string
}

const renderDateTime = (text: string, record: ICessionDate) : ReactElement => {
  const starts_at = record.attributes.starts_at;
  const ends_at = record.attributes.ends_at;

  return <span>{ starts_at.format(timeFormats.display) } - { ends_at.format(timeFormats.display) }</span>;
};

const DatesList: React.FC<DatesListProps> = ({ cessionId }) => {
  const apiEndpoint = React.useMemo(() => api.cessionDates(cessionId), [cessionId]);

  const { tableProps, error, refresh, reload } = useInnerTable<ICessionDate>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ICessionDate> => {
    return {
      title: "Data de cessió",
      handleCreated: reload,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh, reload]);

  const { create, edit, drawerProps } = useDrawerForm<ICessionDate>(apiEndpoint, formOptions);

  const formOptionsMultiple = React.useMemo(() : useDrawerFormOptionsType<ICessionDate> => {
    return {
      title: "Crear múltiples dates",
      handleCreated: reload,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh, reload]);

  const { create: createMulitple, drawerProps: drawerPropsMultiple } = useDrawerForm<ICessionDate>(apiEndpoint, formOptionsMultiple);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      reload();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<ICessionDate>[] = [
    { title: "Espai", key: "space", dataIndex: ["attributes", "space_name"] },
    { title: "Dia", key: "day", render: renderRecordDate("day") },
    { title: "Hora", key: "hour", render: renderDateTime },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Button icon={<PlusOutlined />} onClick={create} style={{marginRight: "5px"}}>Afegir</Button>
          <Button icon={<PlusOutlined />} onClick={createMulitple}>Afegir múltiple</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <DatesForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerPropsMultiple}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <CreateMultipleDatesForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default DatesList;
