import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import {ICessionResult, ICessionsResult, INewCession} from '../interfaces/Cession'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Cessions extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["from", "to"];

  public async list(params: IQuery = {}): Promise<ICessionsResult> {
    return await this.request(GET, Routes.cessionsPath(), {}, params) as ICessionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICessionResult> {
    return await this.request(GET, Routes.cessionPath(id), {}, params) as ICessionResult
  }

  public async create(params: JsonApiDocument): Promise<ICessionResult> {
    return await this.request(POST, Routes.cessionsPath(), {}, params) as ICessionResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICessionResult> {
    return await this.request(PATCH, Routes.cessionPath(id), {}, params) as ICessionResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.cessionPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : INewCession {
    return {
      id: null,
      type: "cessions",
      attributes: {
        predicted_participants: null,
        real_participants: null,
        observations: "",
        age_ids: [],
        material_ids: [],
        target_ids: []
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}