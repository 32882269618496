import React from "react"
import {DatePicker, Form, Input, TimePicker, Row, Col, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats, timeFormats} from "utils/formats";
import { rowConfig, oneColumn, twoColumns } from "utils/constants";
import { useAxiosRequest } from "use-axios-request";
import { Routes } from "api/routes";
import { IDictionary } from "api/interfaces/Dictionary";
import { axiosConfig } from "utils/request";
import { filterOptionByLabel } from "utils/helpers";

export interface DateFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };

const DateForm: React.FC<DateFormProps> = ({ readOnly = false }) => {
  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  return (
    <>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "day"]} label="Dia" rules={rules.required}>
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "starts_at"]} label="Hora d'inici" rules={rules.required}>
            <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "ends_at"]} label="Hora fi" rules={rules.required}>
            <TimePicker className="w100" disabled={readOnly} minuteStep={15} format={timeFormats.display} placeholder="Selecciona una hora" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...oneColumn}>
          <Form.Item name={["attributes", "space_id"]} label="Espai" rules={rules.required}>
            <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingSpaces} filterOption={filterOptionByLabel} showSearch>
              {spaces.map((item) => (
                <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig} className="mb-20">
        <Col {...oneColumn}>
            <Form.Item name={["attributes", "observations"]}>
              <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>
      </Row>
    </>
  )
};

export default DateForm;
