import React, { useState } from "react";
import {Form, Row, Col, Input, Button, DatePicker, Select, Collapse, InputNumber} from "antd";
import {rowConfig, fourColumns, twoColumns, BooleanStatus, OnlineOptions} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IActivity} from "api/interfaces/Activity";
import {IQueryEditable} from "../../api/interfaces/Query";
import { dateFormats } from "utils/formats";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useAxiosRequest } from "use-axios-request";
import { IDictionary } from "api/interfaces/Dictionary";
import { filterOptionByLabel } from "utils/helpers";
import {IEntity} from "../../api/interfaces/Entity";

interface ActivityFormProps {
  api: IQueryEditable,
  record: IActivity,
  handleCreated?: (item: IActivity | IActivity[]) => void,
  handleUpdated?: (item: IActivity) => void,
}

class InnerForm extends PageForm<IActivity> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const activitySeasonsRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_seasons"), params: { items: "all" } };
const activityCategoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_categories"), params: { items: "all" } };
const activityTypesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("activity_types"), params: { items: "all" } };
const agesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("ages"), params: { items: "all" } };
const taxesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("tax_categories"), params: { items: "all" } };
const entitiesRequest = { ...axiosConfig, baseURL: "/", url: Routes.entitiesPath(), params: { items: "all" } };

const ActivityForm: React.FC<ActivityFormProps> = ({ api, record, handleCreated = () => void 0, handleUpdated = () => void 0 }) => {
  const [form] = Form.useForm();
  const { getFieldValue } = form;

  const { data: activitySeasonsData, isFetching: isFetchingActivitySeasons } = useAxiosRequest<{ data: IDictionary[] }>(activitySeasonsRequest);
  const { data: activitySeasons = [] } = activitySeasonsData || {};

  const { data: activityCategoriesData, isFetching: isFetchingActivityCategories } = useAxiosRequest<{ data: IDictionary[] }>(activityCategoriesRequest);
  const { data: activityCategories = [] } = activityCategoriesData || {};
  const { data: activityTypesData, isFetching: isFetchingActivityTypes } = useAxiosRequest<{ data: IDictionary[] }>(activityTypesRequest);
  const { data: activityTypes = [] } = activityTypesData || {};

  const { data: agesData, isFetching: isFetchingAges } = useAxiosRequest<{ data: IDictionary[] }>(agesRequest);
  const { data: ages = [] } = agesData || {};

  const { data: taxesData, isFetching: isFetchingTaxes } = useAxiosRequest<{ data: IDictionary[] }>(taxesRequest);
  const { data: taxes = [] } = taxesData || {};

  const { data: entitiesData, isFetching: isFetchingentities } = useAxiosRequest<{ data: IEntity[] }>(entitiesRequest);
  const { data: entities = [] } = entitiesData || {};

  const [state, setState] = useState({
    autocalculateAttendees: form.getFieldValue(["attributes", "autocalculate_attendees"])
  })

  const onValuesChange = (changedValues, values) => {
    const attributes = changedValues["attributes"] || {};

    if (attributes["autocalculate_attendees"] !== undefined)
      setState({...state, autocalculateAttendees: attributes["autocalculate_attendees"]})
  }

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleUpdated}
      onValuesChange={onValuesChange}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Collapse bordered={false} className="mb-20">
            <Collapse.Panel header="Activitat" key="1">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["id"]} label="Codi" >
                    <Input type="text" disabled placeholder="Codi" />
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
                    <Input type="text" disabled={readOnly} placeholder="Nom" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "online"]} label="Online">
                    <Select disabled={readOnly} placeholder="Selecciona un element">
                      {Object.keys(OnlineOptions).map((key) => (
                        <Select.Option key={key} value={OnlineOptions[key]}>
                          {key}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "hours"]} label="Hores">
                    <InputNumber type="text" disabled={readOnly} placeholder="Número de hores" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "schedule"]} label="Horari">
                    <Input type="text" disabled={readOnly} placeholder="Horari" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "start_date"]} label="Data d'inici">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "end_date"]} label="Data de fi">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_season_id"]} label="Temporada" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivitySeasons} filterOption={filterOptionByLabel} showSearch>
                      {activitySeasons.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_category_id"]} label="Categoria" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityCategories} filterOption={filterOptionByLabel} showSearch>
                      {activityCategories.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "activity_type_id"]} label="Tipus" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingActivityTypes} filterOption={filterOptionByLabel} showSearch>
                      {activityTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "places_obertes"]} label="Places obertes">
                    <Select disabled={readOnly} placeholder="Selecciona un element">
                      {Object.keys(BooleanStatus).map((key) => (
                        <Select.Option key={key} value={BooleanStatus[key]}>
                          {key}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

              </Row>
              <Row {...rowConfig}>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "organizer_ids"]} label="Organitzadors">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingentities} filterOption={filterOptionByLabel} showSearch>
                      {entities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...twoColumns}>
                  <Form.Item name={["attributes", "collaborator_ids"]} label="Col·laboradors">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingentities} filterOption={filterOptionByLabel} showSearch>
                      {entities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Descripció" key="2">
              <Form.Item name={["attributes", "description"]} label="Descripció" rules={rules.required}>
                <Input.TextArea disabled={readOnly} placeholder="Descripció" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel header="Configuració" key="3">
              <Row {...rowConfig}>
                {/*
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "highlighted"]} label="Destacat">
                    <Radio.Group disabled={readOnly}>
                      <Radio value={false}>No</Radio>
                      <Radio value={true}>Sí</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              */}
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "published_at"]} label="Publicat">
                    <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "age_ids"]} label="Edats">
                    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingAges} filterOption={filterOptionByLabel} showSearch>
                      {ages.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Assistència" key="4">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "seats"]} label="Places">
                    <InputNumber type="text" disabled={readOnly} placeholder="Número de places" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "seats_for_sale"]} label="Places disponibles a la web">
                    <InputNumber type="text" disabled={readOnly} placeholder="Número de places disponibles a la web" />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "attendees"]} label="Nº d'assistents">
                    <InputNumber
                      addonBefore={
                        <Form.Item name={["attributes", "autocalculate_attendees"]} label={false} className="mb-0" style={{height: "30px"}}>
                          <Select disabled={readOnly}>
                            <Select.Option key="false" value={false}>Manual</Select.Option>
                            <Select.Option key="true" value>Automàtic</Select.Option>
                          </Select>
                        </Form.Item>
                      }
                      type="text" disabled={readOnly || getFieldValue(["attributes", "autocalculate_attendees"])} placeholder="Número de participants"
                      // addonAfter={!readOnly && getFieldValue(["attributes", "autocalculate_attendees"]) && <ReloadOutlined onClick={() => console.log("hola")} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Preus" key="5">
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "tax_category_id"]} label="Tax" rules={rules.required}>
                    <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingTaxes} filterOption={filterOptionByLabel} showSearch>
                      {taxes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price"]} label="Preu">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_per_hour"]} label="Preu per hora">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                {/*
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_unemployed"]} label="Preu Aturats">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_invitation"]} label="Preu Invitació">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_disability_50"]} label="Preu Discapacitat 50">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_disability_75"]} label="Preu Discapacitat 75">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_ss_50"]} label="Preu SS 50">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_ss_75"]} label="Preu SS 75">
                    <InputNumber type="text" disabled={readOnly} placeholder="0.00 €" decimalSeparator="," precision={2} />
                  </Form.Item>
                </Col>
                */}
              </Row>
              {/*
              <Row {...rowConfig}>
                <Col {...fourColumns}>
                  <Form.Item name={["attributes", "price_ss_100"]} label="Preu SS 100">
                    <InputNumber type="text" disabled={readOnly} placeholder="Preu SS 100"/>
                  </Form.Item>
                </Col>
              </Row>
              */}
            </Collapse.Panel>
            <Collapse.Panel header="Observacions" key="6">
              <Form.Item name={["attributes", "observations"]}>
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default ActivityForm;
