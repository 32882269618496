import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IActivityResult, IActivitiesResult, IActivity } from '../interfaces/Activity'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import moment from 'moment'

export default class Activities extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["start_date", "end_date", "published_at"];

  public async list(params: IQuery = {}): Promise<IActivitiesResult> {
    return await this.request(GET, Routes.activitiesPath(), {}, params) as IActivitiesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IActivityResult> {
    return await this.request(GET, Routes.activityPath(id), {}, params) as IActivityResult
  }

  public async create(params: JsonApiDocument): Promise<IActivityResult> {
    return await this.request(POST, Routes.activitiesPath(), {}, params) as IActivityResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IActivityResult> {
    return await this.request(PATCH, Routes.activityPath(id), {}, params) as IActivityResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.activityPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IActivity {
    return {
      id: null,
      type: "activities",
      attributes: {
        name: "",
        start_date: moment.utc(),
        end_date: moment.utc(),
        observations: "",
        activity_season_id: null,
        activity_category_id: null,
        activity_type_id: null,
        vault_id: null,
        tax_category_id: null,
        price: null,
        price_per_hour: null,
        price_unemployed: null,
        price_invitation: null,
        price_disability_50: null,
        price_disability_75: null,
        price_ss_50: null,
        price_ss_75: null,
        price_ss_100: null,
        description: null,
        hours: null,
        schedule: null,
        highlighted: null,
        published_at: moment.utc(),
        seats: null,
        seats_for_sale: null,
        places_obertes: null,
        online: null,
        autocalculate_attendees: false,
        attendees: null
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
