import React from "react";
import {Button, Table, Alert, message} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {ModalFormChildProps, ModalFormWithForwardRef} from "components/ModalFormApi";
import {ICessionIndividual} from "api/interfaces/cession/Individual";
import RemoteSelector from "components/Forms/RemoteSelector";

interface IndividualsListProps {
  cessionId: string
}

const IndividualsList: React.FC<IndividualsListProps> = ({ cessionId }) => {
  const apiEndpoint = React.useMemo(() => api.cessionIndividuals(cessionId), [cessionId]);

  const { tableProps, error, refresh, reload } = useInnerTable<ICessionIndividual>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<ICessionIndividual> => {
    return {
      title: "Afegir individu",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps } = useDrawerForm<ICessionIndividual>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<ICessionIndividual>[] = [
    { title: "Nom", key: "name", dataIndex: ["attributes","individual_name"] },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(null, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["cessions:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <RemoteSelector
            form={form}
            readOnly={readOnly}
            valuePath={["id"]}
            labelPath={["attributes", "full_name"]}
            attributeLabel="Individu"
            attributeName={["attributes", "individual_id"]}
            queryParam="name"
            endpoint={api.individuals} />
        )}
      </ModalFormWithForwardRef>
    </>
  );
};

export default IndividualsList;
