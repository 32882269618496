import React from "react";

import {Form, Table, Alert, Button, Input, message} from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery } from "hooks/useRouter";
import { renderRecordActions, renderBooleanColumn } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import { DrawerFormChildProps, DrawerFormWithForwardRef } from "components/DrawerFormApi";
import api from "api";
import {IEntity} from "api/interfaces/Entity";
import EntityForm from "./form";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined
  })
}

const EntitiesList: React.FC = () => {
  // useWhyDidYouUpdate('EntitiesList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const { filters: query } = useQuery();

  const { tableProps, error, refresh, reload, search } = useTable<IEntity>(api.entities, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IEntity> => {
    return {
      title: "Nova entitat",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.entities.newInstance()
    }
  }, [refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IEntity>(api.entities, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await api.entities.destroy(id);

    if (response.isSuccess())
      reload();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IEntity>[] = [
    {
      title: 'Entitat',
      dataIndex: ["attributes", 'name'],
      key: "name"
    },
    {
      title: 'Telèfons',
      key: "phones",
      dataIndex: ["attributes", "phones"]
    },
    {
      title: 'E-mail',
      key: "email",
      dataIndex: ["attributes", "email"]
    },
    {
      title: 'Servei',
      key: "service",
      render: renderBooleanColumn("is_service")
    },
    {
      title: 'Àmbits',
      key: "ambits",
      dataIndex: ["attributes", "ambit_names"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="name">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["entities:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <EntityForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default EntitiesList;
