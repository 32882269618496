import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Card, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

const { Title } = Typography;

const Sections: React.FC = ({ children }) => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { url } = useRouteMatch();

  return (
    <PageHeaderWrapper>
      <Link to={`${url}/dictionaries`}>
        <Card style={{ width: 300 }}>
          <Title level={3} style={{textAlign: "center", marginBottom: 0}}>
          <BookOutlined style={{display: "block", marginBottom: "15px"}} />
            Taules diccionari
          </Title>
        </Card>
      </Link>
    </PageHeaderWrapper>
  );
};

export default Sections;
