import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IActivitySessionResult, IActivitySessionsResult, IActivitySession } from '../../interfaces/activity/Session'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class ActivitySessions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly activity_id: string;

  protected dateFields: string[] = ["session_date"];
  protected dateTimeFields: string[] = ["starts_at", "ends_at"];

  constructor(activity_id: string, host?: string) {
    super(host);
    this.activity_id = activity_id;
  }

  public async list(params: IQuery = {}): Promise<IActivitySessionsResult> {
    return await this.request(GET, Routes.activitySessionsPath(this.activity_id), {}, params) as IActivitySessionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IActivitySessionResult> {
    return await this.request(GET, Routes.activitySessionPath(this.activity_id, id), {}, params) as IActivitySessionResult
  }

  public async create(params: JsonApiDocument): Promise<IActivitySessionResult> {
    return await this.request(POST, Routes.activitySessionsPath(this.activity_id), {}, params) as IActivitySessionResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IActivitySessionResult> {
    return await this.request(PATCH, Routes.activitySessionPath(this.activity_id, id), {}, params) as IActivitySessionResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.activitySessionPath(this.activity_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attributes = {}) : IActivitySession {
    return {
      id: null,
      type: "activity_sessions",
      attributes: {
        ends_at: moment.utc(),
        observations: "",
        session_date: moment.utc(),
        starts_at: moment.utc(),
        activity_id: null,
        activity_name: "",
        space_id: null,
        space_name: "",
        tallerista_id: null,
        tallerista_name: "",
        material_ids: [],
        activity_session_report_attributes: {
          initial_description: "",
          main_description: "",
          final_description: "",
          considerations: "",
          adaptations_to_diversity: "",
          feedback: ""
        },
        ...attributes
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
