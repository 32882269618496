import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from "antd";
// import { presetPalettes } from "@ant-design/colors";
import React from "react";

import HeaderDropdown from "components/HeaderDropdown";
import styles from "./index.module.less";
import { useStoreState, useStoreActions } from "utils/store";
import { useHistory } from "react-router-dom";
import { getContrast } from 'utils/helpers';
import { useDrawerForm, useDrawerFormOptionsType } from 'hooks/useDrawerFormApi';
import { IUser } from 'api/interfaces/User';

import api from "api";
import { DrawerFormChildProps, DrawerFormWithForwardRef } from 'components/DrawerFormApi';
import UserForm from 'pages/users/form';

// const availablePalettes = Object.values(presetPalettes);

const AvatarDropdown: React.FC = () => {
  const history = useHistory();
  const singOut = useStoreActions(actions => actions.app.signOut);
  const setCurrentAppUser = useStoreActions(actions => actions.app.setCurrentUser);

  const { currentUser = { attributes: { initials: "", familiar_name: "", avatar_color: "#bbc4c7" } } } = useStoreState(state => state.app);

  const avatarStyles = () : React.CSSProperties => {
    // const { currentUser = { email: "" } } = this.props;

    // const backgroundColor = availablePalettes[currentUser.attributes.email.length % availablePalettes.length][4];
    // const color = availablePalettes[currentUser.attributes.email.length % availablePalettes.length][9];

    return { backgroundColor: currentUser.attributes.avatar_color, color: getContrast(currentUser.attributes.avatar_color) };
    // return { backgroundColor, color };
  };

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IUser> => {
    const refreshCurrentUser = (item: IUser) => {
      setCurrentAppUser(item);
    };

    return {
      title: "Perfil d'usuari",
      handleCreated: refreshCurrentUser,
      handleUpdated: refreshCurrentUser,
      newRecord: api.users.newInstance()
    }
  }, [setCurrentAppUser])

  const { edit, drawerProps } = useDrawerForm<IUser>(api.users, formOptions);

  const onMenuClick = (event) : void => {
    const { key } = event;

    if (key === "logout") {
      singOut({ history });
      return;
    } else if (key === "profile") {
      edit(currentUser.id)
      return;
    }

    history.push(`/auth/${key}`);
  };

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="profile">
        <UserOutlined />
        Perfil d&rsquo;usuari
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        Tancar sessió
      </Menu.Item>
    </Menu>
  );

  return currentUser && currentUser.attributes.familiar_name ? (
    <>
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Avatar className={styles.avatar} alt="avatar" style={avatarStyles()}>{currentUser.attributes.initials}</Avatar>
          <span className={styles.name}>{currentUser.attributes.familiar_name}</span>
        </span>
      </HeaderDropdown>
      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <UserForm profile form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  ) : (
    <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
  );
};

export default AvatarDropdown;
