import React, {ReactElement, useCallback, useEffect, useMemo, useReducer} from "react";
import moment from "moment";
import {PageLoading} from "@ant-design/pro-layout";
import { find } from "lodash";

import api from "api";
import LoadingError from "components/LoadingError";
import NotFound from "components/NotFound";
import {IActivityAttendance, IActivityAttendancesGridResponse} from "api/interfaces/activity/Attendance";
import {Radio, message, Table} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { IActivityRegistration } from "api/interfaces/activity/Registration";
import { IActivitySession } from "api/interfaces/activity/Session";

interface AttendancesProps {
  activityId: string
}

interface IState {
  data?: IActivityAttendancesGridResponse["data"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  data: null,
  isFetching: false,
  error: null,
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const findAttendance = (registration, session, attendances : IActivityAttendance[]) => {
  const attendance = find(attendances, (a) => {
    return String(a.attributes.activity_registration_id) === String(registration.id) &&
      String(a.attributes.activity_session_id) === String(session.id)
  });

  return attendance ? attendance.attributes.kind : "absence";
};

const Attendances: React.FC<AttendancesProps> = ({ activityId }) => {
  const apiEndpoint = React.useMemo(() => api.activityAttendances(activityId, "all"), [activityId]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiEndpoint.grid({});

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            data: result.success().data,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            data: null,
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (activityId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [apiEndpoint, activityId, dispatch]);

  const onChange = useCallback((registration, session) => async (ev) => {
    const response = await apiEndpoint.saveGrid(session.id, {
      activity_registration_id: registration.id,
      kind: ev.target.value
    });

    if (response.isFail()) {
      message.error(`No s'ha pogut guardar l'assistència de: ${registration.attributes.who}`, 10);
    }

  }, [apiEndpoint]);

  const {registrations = {data: []}, attendances = {data: []}, sessions = {data: []}} = state.data || {};

  const columns: ColumnType<IActivityRegistration>[] = useMemo(() => {
    const renderAttendanceButtons = (session: IActivitySession) => {
      return (text: string, registration: IActivityRegistration) : ReactElement => {
        const notCancelled = registration.attributes.cancelled_at == null || registration.attributes.cancelled_at >= session.attributes.session_date;
        const validForSession = registration.attributes.accepted_at <= session.attributes.session_date;
        // We parse the date because the response is a nested response and we don't parse nested elements
        const validWeekDay = registration.attributes.week_days.includes(moment.utc(session.attributes.session_date).day().toString());

        if (notCancelled && validForSession && validWeekDay) {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <Radio.Group defaultValue={findAttendance(registration, session, attendances.data)} buttonStyle="solid" onChange={onChange(registration, session)} size="small">
                <Radio.Button className="attends" value="attends">Sí</Radio.Button>
                <Radio.Button className="justified_absence" value="justified_absence">Abs. just.</Radio.Button>
                <Radio.Button className="absence" value="absence">No</Radio.Button>
              </Radio.Group>
            </div>
          )
        } else {
          return (<div style={{ whiteSpace: "nowrap" }}>-</div>)
        }
      };
    };

    const tableColumns: ColumnType<IActivityRegistration>[] = [
      {
        title: 'Nom',
        render: (text, record) => (
          <div style={{ whiteSpace: "nowrap" }}>
            {record.attributes.who}
          </div>
        ),
        key: "attribues.who",
        fixed: 'left',
      }
    ];

    sessions.data.forEach((session) => {
      tableColumns.push({
        title: session.attributes.session_date,
        key: `session.${session.id}`,
        render: renderAttendanceButtons(session),
        align: "center",
        width: "550px"
      });
    });

    return tableColumns;
  }, [sessions, attendances.data, onChange]);


  if (!activityId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  return (
    <Table columns={columns} dataSource={registrations.data} pagination={false} className="table-nowrap" />
  )
};

export default Attendances;
