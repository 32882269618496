import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IEntityResult, IEntitiesResult, IEntity } from '../interfaces/Entity'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Entities extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<IEntitiesResult> {
    return await this.request(GET, Routes.entitiesPath(), {}, params) as IEntitiesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IEntityResult> {
    return await this.request(GET, Routes.entityPath(id), {}, params) as IEntityResult
  }

  public async create(params: JsonApiDocument): Promise<IEntityResult> {
    return await this.request(POST, Routes.entitiesPath(), {}, params) as IEntityResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IEntityResult> {
    return await this.request(PATCH, Routes.entityPath(id), {}, params) as IEntityResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.entityPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IEntity {
    return {
      id: null,
      type: "entities",
      attributes: {
        ambit_ids: [],
        ambit_names: "",
        email: "",
        is_service: false,
        name: "",
        observations: "",
        phones: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
