import React from "react";
import { MenuDataItem } from "@ant-design/pro-layout/lib/typings";
import {
  AppstoreOutlined,
  BankOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  IdcardOutlined,
  StarOutlined,
  SolutionOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {} from "@ant-design/icons/lib";

const routes: MenuDataItem[] = [
  {
    key: "dashboard",
    path: "/dashboard",
    name: "Inici",
    icon: <CalendarOutlined />,
    authority: ["dashboard"]
  },
  {
    key: "activities",
    path: "/activities",
    name: "Activitats",
    icon: <AppstoreOutlined />,
    authority: ["activities:index"]
  },
  {
    key: "cessions",
    path: "/cessions",
    name: "Cessions",
    icon: <SolutionOutlined />,
    authority: ["cessions:index"]
  },
  {
    key: "entities",
    path: "/entities",
    name: "Entitats",
    icon: <BankOutlined />,
    authority: ["entities:index"]
  },
  {
    key: "individuals",
    path: "/individuals",
    name: "Individus",
    icon: <IdcardOutlined />,
    authority: ["individuals:index"]
  },
  {
    key: "talleristes",
    path: "/talleristes",
    name: "Talleristes",
    icon: <TeamOutlined />,
    authority: ["talleristes:index"]
  },
  {
    key: "indicators",
    path: "/indicators",
    name: "Consultes",
    icon: <StarOutlined />,
    authority: ["indicators:index"]
  },
  {
    key: "space-stats",
    path: "/space-stats",
    name: "Utilització",
    icon: <CarryOutOutlined />,
    authority: ["spacestats:index"],
  },
  {
    key: "users",
    path: "/users",
    name: "Usuaris",
    icon: <UserOutlined />,
    hideOnMobile: true,
    authority: ["users:manage"]
    // children: [
    //   {
    //     path: "/users/two",
    //     name: "Users Two",
    //     key: "users-two"
    //   },
    // ]
  },
  {
    key: "preferences",
    path: "/preferences",
    name: "Preferències",
    icon: <ToolOutlined />,
    hideOnMobile: true,
    authority: ["preferences"],
    children: [
      {
        name: "Diccionaris",
        path: "/preferences/dictionaries",
        key: "preferences-dictionaries"
      },
    ]
  }
];

export default routes;
