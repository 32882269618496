export const basePath = `api`

export const Routes = {
  activitiesPath: () => `${basePath}/activities`,
  activityPath: (id: string) => `${basePath}/activities/${id}`,
  activityAttendancesPath: (activity_id: string, session_id: string) => `${basePath}/activities/${activity_id}/sessions/${session_id}/attendances`,
  activityRegistrationsPath: (activity_id: string) => `${basePath}/activities/${activity_id}/registrations`,
  activityRegistrationsRecalculateTaxesPath: (activity_id: string) => `${basePath}/activities/${activity_id}/registrations/recalculate-taxes`,
  activityRegistrationPath: (activity_id: string, id: string) => `${basePath}/activities/${activity_id}/registrations/${id}`,
  activitySessionsPath: (activity_id: string) => `${basePath}/activities/${activity_id}/sessions`,
  activitySessionPath: (activity_id: string, id: string) => `${basePath}/activities/${activity_id}/sessions/${id}`,
  applicationMessagesPath: () => `${basePath}/application_messages`,
  applicationMessagePath: (id: string) => `${basePath}/application_messages/${id}`,
  attachmentsPath: (base: string, vaultId: string|number) => `${basePath}/${base}/vaults/${vaultId}/uploads`,
  attachmentPath: (base: string, vaultId: string|number, id: string) => `${basePath}/${base}/vaults/${vaultId}/uploads/${id}`,
  attachmentCollectionsPath: (base: string, vaultId: string|number) => `${basePath}/${base}/vaults/${vaultId}/vaults`,
  attachmentCollectionPath: (base: string, vaultId: string|number, id: string) => `${basePath}/${base}/vaults/${vaultId}/vaults/${id}`,
  calendarTasksPath: () => `${basePath}/calendar/tasks`,
  calendarTaskPath: (id: string) => `${basePath}/calendar/tasks/${id}`,
  cessionsPath: () => `${basePath}/cessions`,
  cessionPath: (id: string) => `${basePath}/cessions/${id}`,
  cessionDatesPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/dates`,
  cessionDatePath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/dates/${id}`,
  cessionEntitiesPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/entities`,
  cessionEntityPath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/entities/${id}`,
  cessionIndividualsPath: (cession_id: string) => `${basePath}/cessions/${cession_id}/individuals`,
  cessionIndividualPath: (cession_id: string, id: string) => `${basePath}/cessions/${cession_id}/individuals/${id}`,
  dictionariesPath: (name: string) => `${basePath}/dictionaries/${name}`,
  dictionaryPath: (name: string, id: string) => `${basePath}/dictionaries/${name}/${id}`,
  entitiesPath: () => `${basePath}/entities`,
  entityPath: (id: string) => `${basePath}/entities/${id}`,
  individualsPath: () => `${basePath}/individuals`,
  individualPath: (id: string) => `${basePath}/individuals/${id}`,
  individualRegistrationsPath: (individual_id: string) => `${basePath}/individuals/${individual_id}/registrations`,
  individualRegistrationPath: (individual_id: string, id: string) => `${basePath}/individuals/${individual_id}/registrations/${id}`,
  paymentsPath: () => `${basePath}/payments`,
  paymentPath: (id: string) => `${basePath}/payments/${id}`,
  talleristesPath: () => `${basePath}/talleristes`,
  talleristaPath: (id: string) => `${basePath}/talleristes/${id}`,
  usersPath: () => `${basePath}/users`,
  userPath: (id: string) => `${basePath}/users/${id}`,
  spaceStatsPath: () => `${basePath}/space_stats`,
  spaceStatPath: (id: string) => `${basePath}/space_stats/${id}`,
};
