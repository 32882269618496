import React, {ReactElement, useCallback} from "react";
import {Popconfirm, Form, Button, Table, Alert, message, Select} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";
import qs from "qs";
import {useStoreState} from "utils/store";
import {  SyncOutlined, PlusOutlined, DownloadOutlined, AuditOutlined  } from "@ant-design/icons";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions as renderDefaultRecordActions, renderRecordDate, renderObservations} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IActivityRegistration} from "api/interfaces/activity/Registration";
import RegistrationForm from "../forms/registration";
import {ModalFormChildProps, ModalFormWithForwardRef} from "../../../components/ModalFormApi";
import CreateRegistrationForm from "../forms/create_registration";
import {useQuery} from "hooks/useRouter";
import { FormInstance } from "antd/lib/form";
import PaymentsList from "pages/shared/payments/list";

interface RegistrationsListProps {
  activityId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: undefined
  })
}


const RegistrationsList: React.FC<RegistrationsListProps> = ({ activityId }) => {
  const [form] = Form.useForm();
  const { filters: query } = useQuery();

  const apiEndpoint = React.useMemo(() => api.activityRegistrations(activityId), [activityId]);

  const { tableProps, error, refresh, reload, search, requestCounter } = useInnerTable<IActivityRegistration>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearch } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const exportURL = `/api/activities/${activityId}/registrations?${qs.stringify({ export: "xlsx"}, { encodeValuesOnly: true, arrayFormat: "brackets" })}`

  const expandedRowRender = useCallback((record: IActivityRegistration): React.ReactNode => {
    return <PaymentsList key={`${activityId}-${requestCounter}`} registrationId={record.id} />;
  },[activityId, requestCounter]) ;

  const createFormOptions = React.useMemo(() : useDrawerFormOptionsType<IActivityRegistration> => {
    return {
      title: "Nova inscripció",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, drawerProps: createDrawerProps } = useDrawerForm<IActivityRegistration>(apiEndpoint, createFormOptions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IActivityRegistration> => {
    return {
      title: "Inscripció",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { edit, drawerProps } = useDrawerForm<IActivityRegistration>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const handleTaxesRecalculation = async () => {
    const response = await apiEndpoint.recalculateTaxes();

    if (response.isSuccess()) {
      message.success('IVA recalculat correctament');
      refresh();
    } else {
      message.error("No s'han pogut recalcular l'IVA de les inscripcions", 10);
    }
  }

  const renderRecordActions = (text: string, record: IActivityRegistration, index: number): ReactElement => {
      return (
        <>
          <Button type="link" icon={<AuditOutlined/>} href={`/api/activities/${activityId}/registrations/${record.id}/print.pdf`} target="_blank" />
          {renderDefaultRecordActions(edit, handleDestroy)(text, record, index)}
        </>
      )
  }

  const columns: ColumnType<IActivityRegistration>[] = [
    { title: "Qui", key: "whow", dataIndex: ["attributes", "who"] },
    { title: "Rebuda", key: "received", render: renderRecordDate("requested_at") },
    { title: "Acceptada", key: "validation", render: renderRecordDate("accepted_at") },
    { title: "Cancel·lada", key: "cancel", render: renderRecordDate("cancelled_at") },
    { title: "Motiu de la baixa", key: "cancel_reason", dataIndex: ["attributes", "cancel_reason_name"] },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="status" label="" >
          <Select placeholder="Selecciona l'estat" showSearch allowClear>
            <Select.Option key="validated" value="validated">Acceptades</Select.Option>
            <Select.Option key="waiting" value="waiting">En espera</Select.Option>
            <Select.Option key="cancelled" value="cancelled">Cancel·lades</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearch}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["activities:create"]}>
          <Popconfirm placement="leftBottom" title="Esteu segurs de recalcular l'IVA de totes les inscripcions?" onConfirm={() => handleTaxesRecalculation()} okText="Sí" cancelText="No">
            <Button icon={<SyncOutlined />} style={{ marginRight: "5px" }}>Recalcular IVA</Button>
          </Popconfirm>

          <Button icon={<PlusOutlined />} onClick={create} style={{ marginRight: "5px" }}>Afegir</Button>
        </PermissibleRender>
        <Button icon={<DownloadOutlined />} href={exportURL} target="_blank">Descarregar</Button>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={{ expandedRowRender }} />

      <ModalFormWithForwardRef {...createDrawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <CreateRegistrationForm form={form} readOnly={readOnly} />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <RegistrationForm form={form} readOnly={readOnly}/>
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default RegistrationsList;
