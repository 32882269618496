import React, {useEffect, useMemo, useReducer} from "react";
import NotFound from "components/NotFound";
import ActivitySessionForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import {useQuery, useRouter} from "hooks/useRouter";
import api from "api";
import LoadingError from "components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import {IActivitySession} from "../../../api/interfaces/activity/Session";
import {PageHeader, Tabs} from "antd";
import Attendances from "./attendances";

export interface IState {
  item?: IActivitySession
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const ActivityEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { activityId, sessionId } = useQuery();
  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);
  const apiEndpoint = useMemo(() => api.activitySessions(activityId), [activityId])

  const goToActivity = (id: string) => router.push(`/activities/${id}`);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiEndpoint.show(sessionId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (activityId && sessionId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [activityId, dispatch, sessionId, apiEndpoint]);

  if (!activityId || !sessionId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  const header = <PageHeader
    style={{padding: 0}}
    onBack={() => goToActivity(activityId)}
    title="Editar sessió"
    subTitle={state.item.attributes.activity_name}
  />

  return (
    <PageHeaderWrapper title={false} content={header}>
      <ActivitySessionForm api={apiEndpoint} record={state.item} />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" animated={false} className="mb-20">
        <Tabs.TabPane tab="Assistència" key="1">
          <Attendances activityId={activityId} sessionId={sessionId} />
        </Tabs.TabPane>
      </Tabs>
    </PageHeaderWrapper>
  );
};

export default ActivityEditPage;
