import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import { PermissibleRender } from '@brainhubeu/react-permissible';

import { useStoreState } from "utils/store";
import Unauthorized from "components/Unauthorized";
import NotFound from "components/NotFound";
import Edit from "./edit";
import Execute from "./execute";
import New from "./new";
import List from "./list";

const QueriesSection: React.FC = ({ children }) => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path } = useRouteMatch();

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  return (
    <Switch>
      <Route exact path={path}>
        <List />
      </Route>

      <Route exact path={`${path}/new`}>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["indicators:create"]} renderOtherwise={<Unauthorized />}>
          <New />
        </PermissibleRender>
      </Route>

      <Route exact path={`${path}/:queryId/execute`}>
        <Execute />
      </Route>

      <Route exact path={`${path}/:queryId`}>
        <Edit />
      </Route>

      <Route component={NotFound} />
    </Switch>
  );
};

export default QueriesSection;
