import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";
import { ResultStatusType } from "antd/lib/result";


const NotFound: React.FC = ({ children }) => {
  const history = useHistory();

  return (
    <Result
      status={"404" as ResultStatusType}
      title="404"
      subTitle="La pàgina que intentes visitar no existeix"
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Tornar a l'inici
        </Button>
      }
    />
  );
};

export default NotFound;
