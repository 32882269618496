import React, { ReactElement, useMemo } from "react";
import { Form, Table, Spin, Alert, Button, DatePicker, message, Tooltip } from "antd";
import { useAxiosRequest } from "use-axios-request";
import { ColumnType, ColumnGroupType } from "antd/lib/table/interface";
import { FormInstance } from "antd/lib/form";
import { find } from "lodash";

import { Routes } from "api/routes";
import { axiosConfig } from "utils/request";
import { IDictionary } from "api/interfaces/Dictionary";
import { useStoreState } from "utils/store";
import {useRouter, useQuery} from "hooks/useRouter";
import { ISpaceStat } from "api/interfaces/SpaceStat";

import api from "api"
import qs from "qs";
import { useTable } from "hooks/useTableApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";

import { dateFormats, dateFromServer } from "utils/formats";
import { renderRecordActions, renderRecordDate, renderRecordTime } from "utils/helpers";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    start_date: undefined,
    end_date: undefined
  })
}

const renderStats = (spaceID) => (text: string, record: ISpaceStat) : ReactElement => {
  const spaceStats = find(record.attributes.stats_attributes, { space_id: parseInt(spaceID) });

  return <span>{ spaceStats ? spaceStats["dones"] : '-' } / { spaceStats ? spaceStats["homes"] : '-' } / { spaceStats ? spaceStats["no_binaris"] : '-' }</span>;
};

const renderStatsTitle = ({ sortOrder, sortColumn, filters }) : ReactElement => {
  return (
    <Tooltip title="Dones / Homes / No binaris">
      <span>D / H / NB</span>
    </Tooltip>
  )
}

const spacesRequest = { ...axiosConfig, baseURL: "/", url: Routes.dictionariesPath("spaces"), params: { items: "all" } };

const RegistryHistory: React.FC = () => {
  const [form] = Form.useForm();
  const router = useRouter();
  const { filters: query } = useQuery();

  const { data: spacesData, isFetching: isFetchingSpaces } = useAxiosRequest<{ data: IDictionary[] }>(spacesRequest);
  const { data: spaces = [] } = spacesData || {};

  const userPermissions = useStoreState(state => state.app.currentPermissions);
  const exportURL = `/api/space_stats?${qs.stringify({filters:query, export: "xlsx"}, { encodeValuesOnly: true, arrayFormat: "brackets" })}`

  const searchQuery = useMemo(() => {
    if (!query) {
      return undefined;
    }

    const { start_date: startAt, end_date: endAt } = query;

    const start_date = startAt ? dateFromServer(startAt) : undefined;
    const end_date = endAt ? dateFromServer(endAt) : undefined;

    return { ...query, start_date, end_date };
  }, [query]);

  const { tableProps, error, refresh, reload, search } = useTable<ISpaceStat>(api.spaceStats, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToCreateSpaceStat = () => router.push(`/space-stats/new`);

  const calculatedColumns = React.useMemo(() => {
    const goToSpaceStat = (id: string) => router.push(`/space-stats/${id}`);

    const handleDestroy = async (id: string) => {
      const response = await api.spaceStats.destroy(id);

      if (response.isSuccess())
        refresh();
      else
        message.error("No s'ha pogut eliminar el registre", 10);
    }

    const columns: (ColumnType<ISpaceStat>|ColumnGroupType<ISpaceStat>)[] = [
      {
        title: 'Data',
        key: 'date',
        render: renderRecordDate("date")
      },
      {
        title: 'De',
        key: 'starts_at',
        align: "center",
        render: renderRecordTime("starts_at")
      },
      {
        title: 'Fins',
        key: 'ends_at',
        align: "center",
        render: renderRecordTime("ends_at")
      }
    ];

    spaces.forEach((space) => {
      columns.push({
        title: space.attributes.name,
        align: "center",
        children: [
          { title: renderStatsTitle, align: "center", key: 'dones', render: renderStats(space.id) },
        ]
      });
    });

    columns.push({
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToSpaceStat, handleDestroy)
    })

    return columns;
  }, [spaces, router, refresh]);

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={searchQuery} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="start_date">
          <DatePicker className="w100" format={dateFormats.display} placeholder="Des de" />
        </Form.Item>
        <Form.Item name="end_date">
          <DatePicker className="w100" format={dateFormats.display} placeholder="Fins" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["spacestats:create"]}>
          <Button icon={<PlusOutlined />} onClick={goToCreateSpaceStat} style={{marginRight: "5px"}}>Afegir</Button>
        </PermissibleRender>
        <Button icon={<DownloadOutlined />} href={exportURL} target="_blank">Descarregar</Button>
      </div>
    </div>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )


  if (isFetchingSpaces) {
    return <Spin />;
  }

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={calculatedColumns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  )
}

export default RegistryHistory;
