import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import './App.less';

import AuthorizedRoute from 'components/Authorized/AuthorizedRoute';
import NotFound from 'components/NotFound';
import Home from 'pages/Home';
import ActivitiesSection from 'pages/activities';
import CessionsSection from 'pages/cessions';
import IndicatorsSection from 'packages/progess-consultes/pages/queries';
import Individuals from 'pages/individuals';
import EntitiesSection from 'pages/entities';
import TalleristesSection from 'pages/talleristes';
import UsersSection from 'pages/users';
import PreferencesDashboard from 'pages/preferences';
import Login from 'pages/auth/login';
import UtilizationSection from 'pages/space-stats';


const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={Login} exact />

      <Redirect from="/" to="/dashboard" exact />
      <AuthorizedRoute path="/dashboard" permissions={["dashboard"]}>
        <Home />
      </AuthorizedRoute>

      <AuthorizedRoute path="/activities" permissions={["activities:index"]}>
        <ActivitiesSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/cessions" permissions={["cessions:index"]}>
        <CessionsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/entities" permissions={["entities:index"]}>
        <EntitiesSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/individuals" permissions={["individuals:index"]}>
        <Individuals />
      </AuthorizedRoute>

      <AuthorizedRoute path="/indicators" permissions={["indicators:index"]}>
        <IndicatorsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/talleristes" permissions={["talleristes:index"]}>
        <TalleristesSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/users" permissions={["users:manage"]}>
        <UsersSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/space-stats" permissions={["spacestats:index"]}>
        <UtilizationSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/preferences" permissions={["preferences"]}>
        <PreferencesDashboard />
      </AuthorizedRoute>

      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
